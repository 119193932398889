<template>
  <v-container fluid>
    <v-row>
      <!-- Lado izquierdo: archivos cargados -->
      <v-col cols="8">
        <v-card class="pa-4" outlined>
          <v-card-title>
            Archivos cargados ({{ files.length }})
            <v-spacer></v-spacer>
            <v-btn color="success" @click="procesarArchivos" :disabled="files.length === 0">
              Procesar Archivos
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-row>
            <v-col
              v-for="(archivo, index) in files"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card outlined>
                <v-card-text>
                  <v-icon color="primary" large>mdi-file-pdf</v-icon>
                  <div class="truncate-text mt-2">{{ archivo.name }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-btn small color="success" @click="previewFile(archivo)">
                    <v-icon small>mdi-eye</v-icon> Ver
                  </v-btn>
                  <v-btn small color="red" @click="removeFile(index)">
                    <v-icon small>mdi-delete</v-icon> Eliminar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Lado derecho: Drag & drop y controles -->
      <v-col cols="4">
        <v-card class="pa-4" outlined>
          <h5 class="mb-4">Carga de archivos</h5>

          <v-select
            v-model="tipoArchivo"
            :items="['FÓRMULA', 'HOJA ENTREGA']"
            label="Tipo de archivo"
            outlined
            dense
            class="mb-4"
          ></v-select>

          <div
            class="drag-drop-area"
            @dragover.prevent
            @drop.prevent="handleDrop"
          >
            <v-icon x-large color="grey">mdi-file-upload</v-icon>
            <p>Arrastra tus archivos PDF aquí o haz clic abajo</p>
            <v-btn color="primary" @click="selectFiles">
              Seleccionar Archivos
            </v-btn>
            <input type="file" multiple ref="fileInput" hidden @change="onFileChange" accept="application/pdf" />
          </div>

          <v-progress-linear :value="uploadProgress" v-if="uploading" color="blue" height="6"></v-progress-linear>

          <v-divider class="my-4"></v-divider>
          <div class="text-caption text-right">Máximo 2000 archivos, envíos por lotes automáticos de 50</div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showPreview" max-width="900px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Vista previa de archivo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showPreview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <iframe :src="previewSrc" width="100%" height="600px"></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tipoArchivo: '',
      files: [],
      showPreview: false,
      previewSrc: '',
      uploadProgress: 0,
      uploading: false,
    };
  },
  methods: {
    selectFiles() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      const newFiles = Array.from(e.target.files);
      this.files.push(...newFiles);
    },
    handleDrop(e) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      this.files.push(...droppedFiles);
    },
    previewFile(archivo) {
      this.previewSrc = URL.createObjectURL(archivo);
      this.showPreview = true;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async procesarArchivos() {
      if (this.files.length === 0) return;
      this.uploading = true;
      const batchSize = 50;
      for (let i = 0; i < this.files.length; i += batchSize) {
        const batch = this.files.slice(i, i + batchSize);
        const formData = new FormData();
        formData.append('tipoArchivo', this.tipoArchivo);
        batch.forEach((f) => formData.append('archivos', f));

        await this.$axios.post('/upload-batch', formData, {
          onUploadProgress: (e) => {
            this.uploadProgress = Math.round((e.loaded * 100) / e.total);
          },
        });
      }
      this.uploading = false;
      this.files = [];
      this.uploadProgress = 0;
      this.$toast.success('Archivos enviados y en proceso');
    },
  },
};
</script>

<style scoped>
.drag-drop-area {
  border: 2px dashed #ccc;
  padding: 30px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
